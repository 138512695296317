import Order from "@/models/Order";

export default {
  methods: {
    createFormationOrder(params = {}) {
      let entityType =  params.entityType
      // if (["LLC", "C Corp", "S Corp", "Nonprofit"].indexOf(params.entityType) != -1) {
      //   entityType = params.entityType.toLowerCase().replace(" ", "_");
      // }
      
      let baseUri = `/orders/create/formation`;		      
      if (entityType || params.state) baseUri += "?";
      if (entityType) baseUri += `entity=${entityType}`;
      if (entityType && params.state) baseUri += "&";
      if (params.state) baseUri += `state=${params.state}`;
      if (params.companyName) baseUri += `&companyName=${params.companyName}`;
      this.$router.push(baseUri);
    },
  },
};
