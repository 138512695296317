import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    mounted() {
        AOS.init({
            once: true,
        })
    },
    updated() {
        this.$nextTick(function () {
          AOS.refreshHard() // fixes the content reanimating on DOM changes (when using v-ifs and v-shows)
        })
      }
}