export default {
  methods: {
    async fireForetellEvent(eventType, payload = {}) {
      payload['event_type'] = eventType
      if (this.$route.query.utm_source) {
        payload['source'] = this.$route.query.utm_source;
      }
      if (this.$route.query.utm_medium) {
        payload['medium'] = this.$route.query.utm_medium;
      }
      if (this.$route.query.gcid) {
        payload['google_campaign_id'] = this.$route.query.gcid;
      }
      if (this.$route.query.gagid) {
        payload['google_ad_group_id'] = this.$route.query.gagid;
      }
      if (this.$route.query.gtid) {
        payload['google_target_id'] = this.$route.query.gtid;
      }
      if (this.$route.query.gd) {
        payload['source_device'] = this.$route.query.gd;
      }
      if (this.$route.query.asrc) {
        payload['assist_source'] = this.$route.query.asrc;
      }
      if (this.$route.query.ssg) {
        payload['source_segment'] = this.$route.query.ssg;
      }
      this.$nextTick(() => {
        if (eventType == 'email_submitted') {
          this.$gtag.set({'user_data': {
              'email': payload.user_email
            }
          })
          this.$gtag.event('conversion', {
            'send_to': 'AW-1000718781/kmiGCNC2wZ0DEL2Dl90D'
          })
        }
        if (eventType == 'subscribed' || eventType == 'sale' || eventType == 'bf_sale') {
          this.$gtag.event('conversion', {
            'send_to': 'AW-1000718781/PsSoCNrpv4cYEL2Dl90D',
            'value': payload.score
          })
        }
        this.$gtag.event(eventType, {
          'event_category': 'Foretell'
        });
      })
      window.uetq = window.uetq || [];
      window.uetq.push('event', eventType, {'revenue_value': payload.score, 'currency': 'USD'});
      if (eventType == 'subscribed' || eventType == 'sale' || eventType == 'bf_sale') {
        window.uetq.push('event', 'universal_conversion', {'revenue_value': payload.score, 'currency': 'USD'});
      }
      this.$axios.setHeader('Access-Control-Allow-Origin', '*')
      try {
        const response = await this.$axios.$post('/foretell_events/api_create', { 'event_type': eventType, 'event': payload })
      } catch(err){
        console.error(err)
      };
    },

    async createOrLoadUserWithForetellEvent(eventType, payload = {}) {
      if (process.client && !this.$store.state.user.data.id) {
        let params = {
          'create': true,
          'utm_source': this.$route.query.utm_source,
          'utm_medium': this.$route.query.utm_medium,
          'utm_campaign': this.$route.query.utm_campaign,
          'utm_term': this.$route.query.utm_term,
          'network': this.$route.query.network,
          'ct': this.$route.query.ct,
          'px': this.$route.query.px,
          'ft': this.$route.query.ft
        }
        await this.$store.dispatch('user/getUser', params)
      }

      this.fireForetellEvent(eventType, payload)
    }
  }
}
