//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import aos from "~/mixins/aos.js";
import foretell from "~/mixins/foretell.js";
import API from "~/api/api";
import isMobile from "~/mixins/isMobile.js";
import { directive as onClickaway } from "vue-clickaway2";
import orderCreatable from "~/mixins/orderCreateable.js";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  mixins: [isMobile, orderCreatable, foretell, aos],
  directives: {
    onClickaway: onClickaway,
  },
  middleware: "cacheControl",
  data() {
    return {
      showEntitySelector: false,
      selectedEntity: "LLC",
      error: false,
      showVideo: false,
      illustrations: {
        ra: require("~/assets/icons/general/icon-service-ra.svg"),
        aoa: require("~/assets/icons/general/icon-service-aoa.svg"),
        fer: require("~/assets/icons/general/icon-service-fer.svg"),
        cogs: require("~/assets/icons/general/icon-service-cogs.svg"),
        bf: require("~/assets/icons/general/icon-service-bf.svg"),
        boi: require("~/assets/icons/general/icon-service-boi.svg"),
      },
    };
  },
  head() {
    if (this.pageInfo && this.metaInfo) {
      let domain = "https://www.legalnature.com";
      let canonicalLink = domain;
      let meta = [
        { name: "description", content: this.metaInfo.description },
        { name: "keyword", content: this.metaInfo.keyword },
        { name: "og:image", content: this.metaInfo["og-image"] },
        { name: "og:title", content: this.metaInfo.title },
        { name: "og:description", content: this.metaInfo.description },
        { name: "og:type", content: "website" },
        { name: "og:url", content: canonicalLink },
      ];
      return {
        title: this.metaInfo.title,
        meta,
        link: [
          {
            rel: "canonical",
            href: canonicalLink,
          },
        ],
      };
    } else {
      return {};
    }
  },
  asyncData({ app, query, params, redirect, error }) {
    return Promise.all([
      API.butterPages.get(app.$axios, "copy-homepage", {
        preview: query.preview ? 1 : 0,
      }),
    ])
      .then((results) => {
        const pageInfo = results[0];

        return {
          pageInfo,
          loading: false,
          error: false,
        };
      })
      .catch((e) => {
        error({ message: "Error fetching data..." });
        return {
          loading: false,
          // error: true,
        };
      });
  },
  mounted() {
    if (process.client) {
      this.createOrLoadUserWithForetellEvent("lnd");
    }
  },
  computed: {
    hero() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.hero.table;
    },
    box1() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.box1.table;
    },
    box2() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.box2.table;
    },
    box3() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.box3.table;
    },
    services() {
      if (this.pageInfo && this.pageInfo.fields.services)
        return this.pageInfo.fields.services.table;
      else return false;
    },
    resources() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.resources_new.table;
    },
    plus() {
      if (this.pageInfo && this.pageInfo.fields.plus)
        return this.pageInfo.fields.plus.table;
    },
    mission() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.mission;
    },
    get_started() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.get_started.table;
    },
    metaInfo() {
      if (this.pageInfo && this.pageInfo.fields)
        return this.pageInfo.fields.meta_information.table;
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
  },
};
